
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { passwordRulesEl } from "@/utils/verify";
const user = namespace("user");
@Component({})
export default class ForgetPwd extends Vue {
  @user.Action getMobileCode;
  @user.Action resetPassword;
  form = {
    account: "",
    verCode: "",
    password: "",
    confirmPassword: ""
  };
  showPwdTips: boolean = false;
  passPass: boolean = false;
  isServerError: boolean = false;
  changeLoading: boolean = false;
  serverErrorMsg: string = "";
  get rules() {
    return {
      account: [
        {
          required: true,
          message: this.$t("login.username-empty-tips"),
          trigger: "blur"
        }
      ],
      verCode: [
        {
          required: true,
          message: this.$t("login.verifiCode-empty-tips"),
          trigger: "blur"
        }
      ],
      password: [{ validator: this.validatePassword, trigger: "change" }],
      confirmPassword: [
        {
          required: true,
          message: this.$t("login.password-empty-tips"),
          trigger: "blur"
        },
        { validator: this.validateConfirm, trigger: "blur" }
      ]
    };
  }
  timer: any;
  countDownNum: number = 60;
  verificationCodeToken: string = "";
  created() {
    // this.getNavData();
  }
  destroyed() {
    // 优化-销毁定时器
    this.timer && clearInterval(this.timer);
  }
  validateConfirm(rule, value, callback) {
    if (value !== this.form.password) {
      callback(new Error(this.$t("login.password-no-same") as string));
    } else {
      callback();
    }
  }
  validatePassword(rule, value, callback) {
    passwordRulesEl(value, callback);
  }
  handleValid(prop, isPass, msg) {
    if (prop === "password") {
      if (isPass) {
        this.passPass = true;
      } else {
        this.passPass = false;
      }
    }
  }
  hasSendCode: boolean = false;
  checkMobile(refName) {
    (this.$refs[refName] as any).validateField("account", errorMessage => {
      if (!errorMessage) {
        this.getVerificationCode();
      }
    });
  }
  async getVerificationCode() {
    if (this.hasSendCode) {
      return;
    }
    this.getMobileCode({
      account: this.form.account,
      validationCodeType: "2"
    })
      .then(data => {
        this.verificationCodeToken = data.data.validationToken;
        this.hasSendCode = true;
        this.timer = setInterval(() => {
          if (--this.countDownNum === 0) {
            clearInterval(this.timer);
            this.hasSendCode = false;
            this.countDownNum = 60;
          }
        }, 1000);
      })
      .catch(data => {
        this.serverErrorMsg = data.message;
        this.isServerError = true;
        this.changeLoading = false;
      });
  }
  submitForm(refName) {
    (this.$refs[refName] as any).validate(async valid => {
      if (valid) {
        this.changeLoading = true;
        this.resetPassword({
          account: this.form.account,
          password: this.form.password,
          verificationCode: this.form.verCode,
          verificationCodeToken: this.verificationCodeToken
        }).then(
          data => {
            this.$router.push("/change-success?from=reset-pwd");
          },
          data => {
            this.serverErrorMsg = data.message;
            this.isServerError = true;
            this.changeLoading = false;
          }
        );
      }
    });
  }
}
